.modal .icon {
    position: absolute;
}

.fullModal select:disabled {
    opacity: 0.75;
}

#AddModal .buttonCard .title {
    font-size: 1rem;
    color: var(--stroke);
    text-align: center;
    text-transform: uppercase;
    padding-top: 0rem;
    font-weight: 600;
    font-family: 'Open Sans';
}

.fullModal {
    background-color: var(--background) !important;
    width: 100%;
    height: 0rem;
    z-index: 1000;
    position: fixed;
    bottom: 0rem;
    right: 0rem;
    left: 0rem;
    overflow-y: auto;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
}

.fullModal .wizard .subtitle {
    text-align: left;
    font-size: 1rem;
    width: 100%;
    color: var(--tableText);
    position: relative;
    margin-top: 1.25rem;
    font-weight: 400;
    font-family: 'Open Sans';
    float: left;
}

.fullModal .wizard .ortext {
    color: var(--tableText);
}

.fullModal.inline {
    left: 2.1875rem;
    top: 2.1875rem;
    right: 2.1875rem;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    width: unset;
    height: 98% !important;
}

.fullModal.opened {
    height: 100%;
    opacity: 1;
    overflow-x: hidden;
}

.fullModal .buttonBall {
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: 600;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
    position: absolute;
    width: 3.9375rem;
    height: 3.9375rem;
    border-radius: 2.0625rem;
    font-size: 1.875rem;
    top: 1.25rem;
    left: 1.25rem;
    z-index: 20;
    color: var(--stroke);
    text-transform: uppercase;
    text-align: center;
    line-height: 2.5rem;
    transition: 0.3s;
}

.fullModal .buttonBall:hover {
    background-color: var(--primaryColor);
    color: var(--white);
    box-shadow: 0 0.125rem 0.1875rem 0 var(--primaryColorOpaque);
    transition: 0.2s;
}

.fullModal .buttonBall:active {
    background-color: #648293;
    transform: scale(0.97);
    box-shadow: none;
    transition: 0.3s;
}

.editor {
    display: inline-block;
    cursor: pointer;
}

.fullModal .buttonBall.edit:before {
    content: '\e949';
    font-size: 1.5rem;
}

.fullModal .buttonBall.close:before {
    content: '\e905';
    font-size: 2.1875rem;
}

.fullModal .buttonBall.close {
    right: 1.25rem;
    left: unset;
}

.fullModal .buttonBall.posture:before {
    content: '\e960';
    font-size: 1.75rem;
}

.fullModal .buttonBall.posture {
    left: 1.25rem;
    right: unset;
}

.fullModal .buttonBall.metrics:before {
    content: '\e957';
    font-size: 1.75rem;
}

.fullModal .buttonBall.events:before {
    content: '\e965';
    font-size: 1.75rem;
}

.fullModal .buttonBall.network-failure:before {
    content: '\e966';
    font-size: 1.75rem;
}

.fullModal .buttonBall.back:before {
    content: '\e908';
}

.fullModal .buttonBall.share {
    right: 10rem;
    left: unset;
}

.fullModal .buttonBall.share:before {
    content: '\e91d';
}

.fullModal .buttonBall.support {
    right: 5.625rem;
    left: unset;
    font-size: 1.4375rem;
}

.fullModal .buttonBall.support:before {
    content: '\e947';
}

.fullModal .buttonLabel {
    font-weight: 600;
    font-family: 'Open Sans', Arial, sans-serif;
    position: absolute;
    bottom: -0.125rem;
    width: 100%;
    text-align: center;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.fullModal .icon {
    min-width: 4.6875rem;
    width: 25%;
    min-height: 21.875rem;
    max-width: 21.875rem;
    background-image: url(/assets/svgs/icon.svg);
    background-size: 75%;
    background-position: top center;
    position: relative;
    float: left;
}

.fullModal .formText {
    width: 35%;
    position: relative;
    float: left;
}

.fullModal .formText .content {
    font-size: .9rem;
    color: var(--tableText);
    font-weight: 400;
    font-family: 'Open Sans';
}

.fullModal .formForm {
    width: 100%;
    max-width: 37.5rem;
    min-width: 21.875rem;
    position: relative;
    margin: auto;
    padding: 3.125rem;
    padding-top: 0rem;
}

.fullModal .formText .title {
    text-align: left;
    width: 100%;
    color: var(--text);
    position: relative;
    font-weight: 300;
    font-family: 'Open Sans';
    font-size: 1.4375rem;
    padding-top: 1.25rem;
    padding-bottom: 0.3125rem;
}

.fullModal .title {
    padding-top: 2.1875rem;
    text-align: center;
    width: 100%;
    color: var(--primaryColor);
    font-family: 'Russo One';
    text-transform: uppercase;
    position: relative;
    font-size: 1rem;
    font-weight: 400;
}

.fullModal .subtitle {
    text-align: center;
    width: 100%;
    color: var(--tableText);
    position: relative;
    margin-bottom: 0rem;
    font-size: 2rem;
    font-weight: 300;
    font-family: 'Open Sans';
    float: left;
}

.fullModal .subtitle.smaller {
    font-size: 1.5rem;
    margin-bottom: 0.9375rem;
}

.fullModal .subhelp {
    text-align: center;
    width: 100%;
    color: var(--primaryColor);
    position: relative;
    margin-bottom: 0rem;
    font-size: 0.875rem;
    font-weight: 700;
    font-family: 'Open Sans';
    width: 18.75rem;
    margin: auto;
    cursor: pointer;
    margin-top: 0.25rem;
    margin-bottom: 0.625rem;
}

.fullModal .subhelp:hover {
    filter: brightness(50%);
}

.fullModal .content {
    text-align: center;
    width: 100%;
    color: var(--icon);
    position: relative;
    font-size: 1rem;
    margin-bottom: 0.625rem;
    font-weight: 300;
    font-family: 'Open Sans';
    float: left;
}

.fullModal .content .summary {
    max-height: 12.5rem;
    overflow: scroll;
}

.fullModal .headerRow {
    position: relative;
    min-height: 31.25rem;
    overflow: visible;
}

.fullModal .fullRow {
    background-position: center right;
    position: relative;
    height: 100%;
}

.specialnote {
    color: var(--tableText);
    font-size: 0.8125rem;
    position: absolute;
    right: 0rem;
    bottom: 0rem;
    text-transform: uppercase;
    font-weight: 600;
}

.launch .tap.aws {
    background-image: url(/assets/images/AwsLogo.png);
    background-size: 16%;
}
